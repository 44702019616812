import classnames from 'classnames'
import { detect } from 'detect-browser'
import { useEffect, useState } from 'react'
import { isMobile, useMobileOrientation } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import '../../styles/reset.css'
import { isValidUuid } from '../../utils/utils'
import { ws } from '../../events/ws'
import Content from '../content/Content'
import { useStateValue } from '../state-provider/StateProvider'
import {
  setBrand,
  setIsLoading,
  setIsSetVideoCurrentTime,
  setItems,
  setParentCard,
  setCurrentCard,
  setSpId,
  setTitleStyle,
  setTotalInteractions,
  setWidgetEmbedded,
  setWidgetLocation,
  setSpHash,
  setSequenceStatistics,
  setLiveStream,
  setWidgetLocationReferer,
  setTranslations,
  setLiveChat,
  setGridAreaContent,
  navigateCard,
  setExperienceRootCard,
} from './App.action'
import {setOrganizationId, setShareUrl} from '../chat/Chat.action'
import './App.css'
import {pure} from "recompose";
import {sendToWidget} from "../../utils/postMessages";
import {OrganizationTranslations} from "../../constants/actions";
import {CardData, SequencePollDataViewModel} from "../../api/types";
import {log} from "../../utils/log";

interface Props {
}

const App: React.FC<Props> = ({ }) => {

  const [
    {
      api_url,
      parentCardId,
      sequencePollHash,
      isWidgetMaximized,
      isWidgetEmbedded,
      header,
      items,
      widgetLocation,
      widgetLocationReferer,
      isSessionEstablished,
      liveStream,
      translations,
      surf,
      surfMode,
      organizationId,
      sessionData,
      liveStreamSubscribed,
    },
    dispatch,
  ] = useStateValue()
  const [preloadedCards, setPreloadedCards] = useState<any[]>([])
  const [prevVideoHeading, setPrevVideoHeading] = useState('')
  const [isContesterBrandingDisplayed, setLogoVisibility] = useState(false)
  const [fonts, setFonts] = useState<{ name: string; url: string }[]>([])
  const [isInitial, setIsInitial] = useState(true)


  let { uuidParam, cardUuidParam } = useParams<{
    uuidParam: string
    cardUuidParam: string
  }>()

  let history = useHistory()
  const location = useLocation()

  let search = new URLSearchParams(location.search)
  if (search.get('experienceId')) {
    //
  }
  if (!uuidParam && search.get('experienceId')) {
    uuidParam = search.get('experienceId')!!
  }
  if (!cardUuidParam && search.get('cardId')) {
    cardUuidParam = search.get('cardId')!!
  }

  function goBack() {
    dispatch(setIsLoading({ isLoading: false }))
    dispatch(setIsSetVideoCurrentTime({ isSetVideoCurrentTime: false }))
    // @ts-ignore
    dispatch(navigateCard(parentCardId, history, "User"))

  }

  // i don't think this block is necessary anymore, it creates this bug that once you navigate to a specific card and then click page refresh, it moves one level up
  //useEffect(() => {
  //  return () => {
  //    if (history.action === 'POP') {
  //      if (cardUuid) {
  //        handleBackNavigation();
  //      }
  //    }
  //  };
  //});

  let handleBeforeunload = () => {
    ws.close()
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload)
    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [])

  useEffect(() => {
    if (dispatch && organizationId && sessionData && Object.keys(translations).length == 0) {
      fetch(api_url + '/i18n/organization/' + organizationId + `?language=${sessionData.languageCode}`)
        .then<OrganizationTranslations>(res => res.json())
        .then(res => {
          dispatch(setTranslations({ translations: res.translations }))
          sendToWidget({ type: 'translations', value: res.translations })
        })

    }
  }, [dispatch, translations, organizationId, sessionData]);

  useEffect(() => {
    if (surf && surfMode) {
      sendToWidget({ type: 'surf', value: { surf: surf, mode: surfMode} })
    }
  }, [dispatch, surf, surfMode]);

  useEffect(() => {
    // This effect opens Safari instead of the Linkedin Browser in iOS devices.
    const browser = detect()
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    let isChromeiOs = browser?.name === 'crios'
    let isIOS = browser?.os === 'iOS'

    if (browser?.name === 'ios-webview') {
      // window.location = 'safari-https://' + window.location.hostname + window.location.pathname;
      window.location.href =
        'googlechrome://' + window.location.hostname + window.location.pathname
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (sequencePollHash === undefined) {
        dispatch(setSpHash({ hash: 'BACKEND_UNAVAILABLE', postToWidget: true }))
      }
      if (widgetLocation === null) {
        dispatch(setWidgetLocation({ location: window.location.href }))
      }
      if (widgetLocationReferer === null) {
        dispatch(setWidgetLocationReferer({ location: document.referrer ?? window.location.href }))
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [sequencePollHash, widgetLocation, widgetLocationReferer])

  useEffect(() => {
    if (isValidUuid(uuidParam)) {
      dispatch(setSpId({ uuid: uuidParam }))
      if (widgetLocation !== null && widgetLocationReferer !== null) {
        ws.init(uuidParam, dispatch, history, widgetLocation, widgetLocationReferer, isWidgetEmbedded)
      }
    } else {
      if (process.env.REACT_APP_ENV === 'development') {
        // dev code
      } else {
        // production code
        window.location.replace('https://contester.net')
        return
      }
    }

    if (isSessionEstablished !== true) {
      return // this is important, otherwise some further API calls which expect established session won't work
    }

    if (sequencePollHash === 'default') {
      return
    }

    if (widgetLocation === undefined) {
      return
    }

    let fetchUrl = api_url + '/sequencepolls/' + uuidParam

    // here we instantiate new image with onload callbacks to know when all images are cached.
    function mapItemImages(items: CardData[]): any[] {

      return items.map((i: CardData) => {
        let img = new Image()
        img.src = i.mediaLink!!
        return {
          ...i,
          image: img,
          title: i.title,
          header: i.header,
        }
      })
    }

    if (isWidgetEmbedded) {
      fetchUrl = appendQueryParameter(fetchUrl, 'widget=true')
    }
    fetchUrl = appendQueryParameter(fetchUrl, 'h=' + sequencePollHash)

    if (location.search.length > 1) {
      // it is important to append only contester_ query params. Otherwise browser and cloudflare caches won't do much for us
      location.search
        .substring(1)
        .split('&')
        .forEach(param => {
          if (param.startsWith('contester')) {
            fetchUrl = appendQueryParameter(fetchUrl, param)
          }
        })
    }

    fetch(fetchUrl)
      .then<SequencePollDataViewModel>(res => res.json())
      .then(async result => {
        dispatch(setIsLoading(true))

        let experience = result.experience

        dispatch(setExperienceRootCard(result.card))


        if (experience.brandFeatureList) {
          sendToWidget({ type: 'brand-feature-list', value: { brandFeatureList: experience.brandFeatureList } })
        }

        if (experience.displayOptions.availableDisplayTypes) {
          sendToWidget({
            type: 'available-display-types',
            value: {
              availableDisplayTypes: experience.displayOptions.availableDisplayTypes,
            }
          })
        }

        let card = navigateCardHierarchy(result.card, cardUuidParam, setPreloadedCards) ?? result.card // it is possible that cardUuidParam is no longer within SP tree

        dispatch(setOrganizationId({ organizationId: experience.organizationId }))

        const styles = JSON.parse(
          card.cardStyleParameters.headingTitleStyle || '{}',
        )
        dispatch(setTitleStyle({ titleStyle: styles }))
        dispatch(setParentCard({ parentCardId: card.parentCardId }))
        dispatch(setSequenceStatistics({ statistics: card.statistics }))
        dispatch(setLiveStream({ liveStream: card.liveStream || null }))
        dispatch(setLiveChat({ liveChat: card.liveChat }))

        if (isInitial &&
          (!liveStreamSubscribed || (card.liveStream?.id && !liveStreamSubscribed)) &&
          (card.liveStream?.notificationSettings?.expandedByDefault == true)
          //@ts-ignore
          && (card.liveStream?.scheduledAt && new Date() < new Date(card.liveStream.scheduledAt * 1000))
        ) {
            dispatch(setGridAreaContent('LiveStreamReminderForm'))
        }
        /**
         * Override videoUrl with optimized variants
         */
        if (isMobile) {
          let mobileOptimizedVideoUrl = card.header.videoUrlsByDeviceType["Mobile"]
          if (mobileOptimizedVideoUrl) {
            card.header.videoUrl = mobileOptimizedVideoUrl
          }
          let mobileOptimizedVideoPoster = card.header.videoPostersByDeviceType?.["Mobile"]
          if (mobileOptimizedVideoPoster) {
            card.header.videoPoster = mobileOptimizedVideoPoster
          }
        } else {
          let desktopOptimizedVideoUrl = card.header.videoUrlsByDeviceType["Desktop"]
          if (desktopOptimizedVideoUrl) {
            card.header.videoUrl = desktopOptimizedVideoUrl
          }
          let desktopOptimizedVideoPoster = card.header.videoPostersByDeviceType?.["Desktop"]
          if (desktopOptimizedVideoPoster) {
            card.header.videoPoster = desktopOptimizedVideoPoster
          }
        }

        try {
          if (card.header.liveStreamUrl || card.header.videoUrl) {
            setPrevVideoHeading(
              new URL((card.header.liveStreamUrl ?? card.header.videoUrl)!!)
                ?.pathname,
            )
          }
        } catch (err) {
          log(`Error set livestreamUrl`, err)
        }

        dispatch(setCurrentCard({ cardUuid: card.cardId }))
        setFonts(experience.fonts.fonts)
        setIsInitial(false)


        if (experience.displayOptions.isContesterBrandingDisplayed) {
          setLogoVisibility(true)
        }

        if (card.cardType !== 'DIRECT_LINK') {
          dispatch({
            type: 'SET_HEADER',
            header:
              card.header.title === '' &&
              card.header.videoUrl === null &&
              card.header.imageUrl === null
                ? null
                : card.header,
          })
        }

        if (card.cardType === 'VIDEO_ONLY') {
          dispatch(setItems({ items: mapItemImages(navigateCardHierarchy(result.card, card.parentCardId, setPreloadedCards)!!.items) }))
        } else {
          dispatch(setItems({ items: mapItemImages(card.items) }))
        }

        dispatch(setBrand({ brand: experience.brand }))
        dispatch(
          setTotalInteractions({
            totalInteractions: card.statistics.totalInteractions,
          }),
        )
        dispatch(setIsLoading(false))

        sendToWidget({ type: 'loaded', value: {} })
      })
      .catch(e => {
        log(e)
      })

    return () => {
      window.removeEventListener('popstate', goBack)
    }
  }, [
    history,
    uuidParam,
    cardUuidParam,
    sequencePollHash,
    dispatch,
    api_url,
    widgetLocation,
    widgetLocationReferer,
    isSessionEstablished,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleWidgetMessage = (event: MessageEvent) => {
    if (event?.data?.type === 'widget-embedded') {
      dispatch(setWidgetEmbedded({ status: true }))
    }
    if (event?.data?.type === 'widget-location') {
      dispatch(setWidgetLocation({ location: event?.data?.value?.href }))
    }
    if (event?.data?.type === 'widget-location-referer') {
      dispatch(setWidgetLocationReferer({ location: event?.data?.value?.href }))
    }
    if (event?.data?.type === 'widget-surf-page-load') {
      ws.sendPageLoad(event?.data?.value?.url)
    }
    if (event?.data?.type === 'iframe-failed-page-load') {
      ws.sendPageLoadFailed(event?.data?.value?.from)
    }
  }

  useEffect(() => {
    sendToWidget({ type: 'widget-status', value: {} })
    window.addEventListener('message', handleWidgetMessage, true)
    return () => {
      window.removeEventListener('message', handleWidgetMessage, true)
    }
  }, [])

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    window.addEventListener('resize', appHeight)
    return () => {
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  useEffect(() => {
    if (isInitial && fonts) {
      fonts.map(font => {
        const style = document.createElement('style')
        style.textContent = `@import url("${font.url}")`

        document.head.appendChild(style)
      })
    }
  }, [fonts, isInitial])

  const { isLandscape } = useMobileOrientation()
  const landscapeMode = isLandscape && isMobile

  const [contentMounted, setContentMounted] = useState(true)

  const remountContent = () => {

    log("remounting <Content/>")
    setContentMounted(false)
    setTimeout(() => {
      setContentMounted(true)
    }, 10)
  }

  const [currentHeaderType, setCurrentHeaderType] = useState<'LiveStream' | 'Video' | undefined>(undefined)
  useEffect(() => {
    if (header) {

      let newHeaderType: 'LiveStream' | 'Video' | undefined = header.liveStreamUrl ? 'LiveStream' : 'Video'
      if (currentHeaderType === undefined) {
        // do nothing
      } else if (currentHeaderType === 'LiveStream' && newHeaderType === 'Video') {
        remountContent()
      } else if (currentHeaderType === 'Video' && newHeaderType === 'LiveStream') {
        remountContent()
      }
      setCurrentHeaderType(newHeaderType)
    }
  }, [header]);

  return (
    <>
      <Helmet>
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
      </Helmet>
      <div className="App">
        <div id="main">
          <div
            id="sequence-poll"
            className={classnames({
              'is-embedded': isWidgetEmbedded,
              'is-maximized': isWidgetMaximized,
              'has-footer-logo': isContesterBrandingDisplayed,
              'no-widget':  window.self === window.top,
              'has-heading':
                header?.videoUrl || header?.liveStreamUrl || header?.imageUrl,
              'is-landscape': landscapeMode,
              'has-header':
                !(items.length == 0 || header === null) &&
                !header?.imageUrl &&
                !header?.videoUrl &&
                !header?.liveStreamUrl,
            })}
          >
            { contentMounted && (
              <Content
                prevVideoHeading={prevVideoHeading}
                setPrevVideoHeading={setPrevVideoHeading}
              />
            )}
          </div>
        </div>
        <div id="brandContent"></div>
      </div>
      <div style={{ display: 'none' }}>
        {preloadedCards.map(card => (
          <img key={card.cardId} src={card.mediaLink} />
        ))}
      </div>
    </>
  )
}

App.whyDidYouRender = true

export default pure(App)



export function appendQueryParameter(fetchUrl: string, param: string): string {
  let url = fetchUrl
  if (url.indexOf('?') > -1) {
    url += '&' + param
  } else {
    url += '?' + param
  }

  return url
}

export function navigateCardHierarchy(tree: CardData, cardId: string | undefined, setPreloadedCards: ((p: any[]) => void) | undefined): CardData | undefined {
  let found = undefined

  if (cardId === undefined || tree.cardId === cardId) {
    found = tree
  }
  if (!found) {
    tree.items.forEach(function (item: any) {
      let result = navigateCardHierarchy(item, cardId, setPreloadedCards)
      if (result) {
        found = result
      }
    })
  } else {
    let preload: any[] = []
    found.items.forEach(function (child: any) {
      preload.push(child)
      child.items.forEach(function (grandChild: any) {
        preload.push(grandChild)
      })
    })
    if (setPreloadedCards) {
      setPreloadedCards(preload)
    }
  }

  return found
}